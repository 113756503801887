<template>
  <div class="system-page page">
    <div class="page-map">上架作業</div>
    <loading :active.sync="isLoading" loader="dots"></loading>
    <div class="card search-card">
      <div class="card-body">
        <div class="form-row">
          <div class="col-sm-3 col-md-2">
            <div class="form-group">
              <label>關鍵字</label>
              <input
                class="form-control"
                type="type"
                v-model="name"
                placeholder="請輸入關鍵字"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-3">
            <button type="button" class="btn btn-primary btn-search">
              查詢
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="text-right mb-2">
      <button class="btn btn-primary"  @click="openModal('New')">
        <eva-icon name="plus-outline" fill="#FFF" width="15" height="15"></eva-icon>
      </button>
    </div> -->
    <div class="web-part-title">採購單</div>
    <div class="table-wrap mb-4">
      <el-table
        :data="receivingOperationList"
        style="width: 100%"
        class="detail--table"
      >
        <!-- :default-sort="{ prop: 'SpType', order: 'descending' }"
            @expand-change="expandSelect"
            :expand-row-keys="expands"
            row-key="SpId" -->
        <!-- <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" class="demo-table-expand">
                  <el-form-item>
                    <table
                      v-show="props.row.SprintBacklogs.length > 0"
                      class="file--table"
                      style="width: 100%"
                    >
                      <tr>
                        <th>Sprint Backlog名稱</th>
                        <th>階段</th>
                        <th>完成率</th>
                        <th>預計完成日</th>
                        <th>預計總工時</th>
                        <th>回報總工時(有效)</th>
                        <th>回報總工時(無效)</th>
                      </tr>
                      <tr
                        v-for="file in props.row.SprintBacklogs"
                        :key="`${file.SbId}${file.SpId}`"
                      >
                        <td>{{ file.SbName }}</td>
                        <td>{{ file.SbLv }}</td>
                        <td>還沒計算</td>
                        <td>{{ file.SbPlanBdate | moment }}</td>
                        <td>{{ file.SbPlanWork}}</td>
                        <td>{{ file.SbValidWork }}</td>
                        <td>{{ file.SbInValidWork}}</td>                       
                      </tr>
                    </table>
                    <div
                      class="text-center"
                      v-show="props.row.SprintBacklogs.length < 1"
                    >
                      尚無工作項目
                    </div>
                    
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column> -->

        <el-table-column label="PO" prop="PO"> </el-table-column>

        <el-table-column label="VENDOR" prop="VENDOR"> </el-table-column>

        <el-table-column label="PN" prop="PN"> </el-table-column>

        <el-table-column label="QTY" prop="QTY"> </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="props">
            <button
              class="btn btn-primary btn-sm"
              @click="openModal('Edit', props.row)"
            >
              <eva-icon
                name="edit-outline"
                fill="#FFF"
                width="15"
                height="15"
              ></eva-icon>
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="web-part-title">調撥單</div>
    <div class="table-wrap mb-4">
      <el-table
        :data="receivingOperationList"
        style="width: 100%"
        class="detail--table"
      >
        <!-- :default-sort="{ prop: 'SpType', order: 'descending' }"
            @expand-change="expandSelect"
            :expand-row-keys="expands"
            row-key="SpId" -->
        <!-- <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" class="demo-table-expand">
                  <el-form-item>
                    <table
                      v-show="props.row.SprintBacklogs.length > 0"
                      class="file--table"
                      style="width: 100%"
                    >
                      <tr>
                        <th>Sprint Backlog名稱</th>
                        <th>階段</th>
                        <th>完成率</th>
                        <th>預計完成日</th>
                        <th>預計總工時</th>
                        <th>回報總工時(有效)</th>
                        <th>回報總工時(無效)</th>
                      </tr>
                      <tr
                        v-for="file in props.row.SprintBacklogs"
                        :key="`${file.SbId}${file.SpId}`"
                      >
                        <td>{{ file.SbName }}</td>
                        <td>{{ file.SbLv }}</td>
                        <td>還沒計算</td>
                        <td>{{ file.SbPlanBdate | moment }}</td>
                        <td>{{ file.SbPlanWork}}</td>
                        <td>{{ file.SbValidWork }}</td>
                        <td>{{ file.SbInValidWork}}</td>                       
                      </tr>
                    </table>
                    <div
                      class="text-center"
                      v-show="props.row.SprintBacklogs.length < 1"
                    >
                      尚無工作項目
                    </div>
                    
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column> -->

        <el-table-column label="PO" prop="PO"> </el-table-column>

        <el-table-column label="VENDOR" prop="VENDOR"> </el-table-column>

        <el-table-column label="PN" prop="PN"> </el-table-column>

        <el-table-column label="QTY" prop="QTY"> </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="props">
            <button
              class="btn btn-primary btn-sm"
              @click="openModal('Edit', props.row)"
            >
              <eva-icon
                name="edit-outline"
                fill="#FFF"
                width="15"
                height="15"
              ></eva-icon>
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="web-part-title">工單完工入庫</div>
    <div class="table-wrap">
      <el-table
        :data="receivingOperationList"
        style="width: 100%"
        class="detail--table"
      >
        <el-table-column label="PO" prop="PO"> </el-table-column>

        <el-table-column label="VENDOR" prop="VENDOR"> </el-table-column>

        <el-table-column label="PN" prop="PN"> </el-table-column>

        <el-table-column label="QTY" prop="QTY"> </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="props">
            <button
              class="btn btn-primary btn-sm"
              @click="openModal('Edit', props.row)"
            >
              <eva-icon
                name="edit-outline"
                fill="#FFF"
                width="15"
                height="15"
              ></eva-icon>
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- detailModal -->
    <div
      class="modal fade"
      data-backdrop="static"
      id="detailModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel">上架細項</div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="field">
              <label class="field-title">來源類別：</label>
              <input type="text" v-model="detailModal.Type_01" />
            </div>
            <div class="field">
              <label class="field-title">來源單號：</label>
              <input type="text" v-model="detailModal.PO" />
            </div>
            <div class="field">
              <label class="field-title">單據型態：</label>
              <input type="text" v-model="detailModal.Type_02" />
            </div>
            <div class="field">
              <label class="field-title">狀態：</label>
              <input type="text" v-model="detailModal.Status" />
            </div>
            <div class="field">
              <label class="field-title">供應商</label>
              <input type="text" v-model="detailModal.VENDOR" />
            </div>
            <div class="field">
              <label class="field-title">料號：</label>
              <input type="text" v-model="detailModal.PN" />
            </div>
            <div class="field">
              <label class="field-title">品名/規格：</label>
              <input type="text" v-model="detailModal.Name" />
            </div>
            <div class="field">
              <label class="field-title">數量：</label>
              <input type="number" v-model="detailModal.QTY" />
            </div>
            <div class="field">
              <label class="field-title">倉庫</label>
              <input type="text" value="A071" />
            </div>
            <div class="field">
              <label class="field-title">儲位</label>
              <input type="text" value="EVT" />
            </div>
            <div class="field">
              <label class="field-title">批號</label>
              <input type="text" value="" />
            </div>
            <div class="field">
              <label class="field-title"
                >挑選Reel
                <button style="float:right" @click="openReelModal" class="btn btn-primary btn-sm">
                  <img src="@/assets/img/scan.png" width="20px" alt="">
                  
                </button></label
              >
              <table class="mb-3">
                <tr>
                  <td><input type="checkbox" /></td>
                  <td>13011-210630-00001</td>
                  <td>100</td>
                  <td>2149</td>
                </tr>
                <tr>
                  <td><input type="checkbox" /></td>
                  <td>13011-210630-00002</td>
                  <td>100</td>
                  <td>2149</td>
                </tr>
                <tr>
                  <td><input type="checkbox" /></td>
                  <td>13011-210630-00003</td>
                  <td>100</td>
                  <td>2149</td>
                </tr>
                <tr>
                  <td><input type="checkbox" /></td>
                  <td>13011-210630-00004</td>
                  <td>100</td>
                  <td>2149</td>
                </tr>
              </table>

              <carousel>
                <label class="check-card">
                  <input type="checkbox" />
                  <eva-icon name="checkmark-circle-2-outline"></eva-icon>
                  13011-210630-00001<br />
                  100<br />
                  2149
                </label>
                <label class="check-card">
                  <input type="checkbox" />
                  <eva-icon name="checkmark-circle-2-outline"></eva-icon>
                  13011-210630-00001<br />
                  100<br />
                  2149
                </label>
                <label class="check-card">
                  <input type="checkbox" />
                  <eva-icon name="checkmark-circle-2-outline"></eva-icon>
                  13011-210630-00001<br />
                  100<br />
                  2149
                </label>
                <label class="check-card">
                  <input type="checkbox" />
                  <eva-icon name="checkmark-circle-2-outline"></eva-icon>
                  13011-210630-00001<br />
                  100<br />
                  2149
                </label>
                <label class="check-card">
                  <input type="checkbox" />
                  <eva-icon name="checkmark-circle-2-outline"></eva-icon>
                  13011-210630-00001<br />
                  100<br />
                  2149
                </label>

              </carousel>
            </div>
          </div>

          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              <eva-icon
                name="close-outline"
                width="15"
                height="15"
                fill="#FFF"
              ></eva-icon>
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              <eva-icon
                name="checkmark-outline"
                width="15"
                height="15"
                fill="#FFF"
              ></eva-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 產生Reel -->
    <div
      class="modal fade"
      data-backdrop="static"
      id="reelModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel">產生Reel</div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row info">
              <div class="col-6">
                <label for="">單號:</label>
                TP021-2109003-1<br />
                <label for="">料號:</label>
                310-100030-00002
              </div>
              <div class="col-6">
                <label for="">數量:</label>
                3,000<br />
                <label for="">倉儲批:</label>
                A071/EVT/
              </div>
            </div>
            <div class="field">
              <label class="field-title">產地：</label>
              <input type="text" />
            </div>
            <div class="field">
              <label class="field-title">製造商：</label>
              <input type="text" />
            </div>
            <div class="field">
              <label class="field-title">來源批號：</label>
              <input type="text" />
            </div>
            <div class="field">
              <label class="field-title">DC：</label>
              <input type="text" />
            </div>
            <div class="field">
              <label class="field-title">PCB</label>
              <input type="text" />
            </div>
            <div class="field">
              <label class="field-title">DRAM</label>
              <input type="text" />
            </div>
            <div class="field">
              <label class="field-title">Register：</label>
              <input type="text" />
            </div>
          </div>

          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              <eva-icon
                name="close-outline"
                width="15"
                height="15"
                fill="#FFF"
              ></eva-icon>
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              <eva-icon
                name="checkmark-outline"
                width="15"
                height="15"
                fill="#FFF"
              ></eva-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */

import carousel from "vue-owl-carousel";

export default {
  data() {
    return {
      isNew: true,
      detailModal: {},
      showCheck: false,
      receivingOperationList: [
        {
          PO: "TP021-2109003-1",
          PN: "310-100030-0002",
          VENDOR: "智翔",
          QTY: "3000",
          Type_01: "採購單",
          Type_02: "原物料採購",
          Status: "待收料",
          Name: "ADAPTER(Witout Logo)",
        },
        {
          PO: "TP021-2109003-1",
          PN: "310-100030-0002",
          VENDOR: "智翔",
          QTY: "3000",
          Type_01: "採購單",
          Type_02: "原物料採購",
          Status: "待收料",
          Name: "ADAPTER(Witout Logo)",
        },
        {
          PO: "TP021-2109003-1",
          PN: "310-100030-0002",
          VENDOR: "智翔",
          QTY: "3000",
          Type_01: "採購單",
          Type_02: "原物料採購",
          Status: "待收料",
          Name: "ADAPTER(Witout Logo)",
        },
        {
          PO: "TP021-2109003-1",
          PN: "310-100030-0002",
          VENDOR: "智翔",
          QTY: "3000",
          Type_01: "採購單",
          Type_02: "原物料採購",
          Status: "待收料",
          Name: "ADAPTER(Witout Logo)",
        },
      ],
    };
  },
  components: {
    carousel,
  },
  methods: {
    openModal(type, detail) {
      const vm = this;
      if (type === "New") {
        vm.isNew = true;
        vm.detailModal = {
          PO: null,
          PN: null,
          VENDOR: null,
          QTY: null,
          Type_01: null,
          Type_02: null,
          Status: null,
          Name: null,
        };
      } else {
        vm.isNew = false;
        vm.detailModal = { ...detail };
      }

      $("#detailModal").modal("show");
    },
    openReelModal() {
      $("#reelModal").modal("show");
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  created() {
    this.getReceivingOperationList();
  },
};
</script>
<style lang="scss" scoped>
.check-card {
  background-color: #f1f1f1;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  i {
    fill: #ccc;
    display: block;
  }
  input {
    display: none;
    &:checked {
      & + i {
        fill: rgb(37, 94, 248);
      }
    }
  }
}
.info {
  margin-bottom: 10px;
  background-color: #f1f7ff;
  padding: 10px;
  margin-top: -16px;
  label {
    display: block;
    margin: 0;
    font-size: 0.8em;
  }
}

.field {
  table {
    width: 100%;
    tr {
      td {
        padding: 3px;
      }
      &:nth-child(even) {
        background-color: #f7f7f7;
      }
    }
  }
}
</style>